import store from '../store'
import axios from 'axios';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

axios.interceptors.response.use(response => {
    
    return (response) ? response : null;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.request.use(function (config) {
    config.headers['use-token'] = store.state.user.authToken; // test 422588688186121165396892813998 
    config.headers['usu-token'] = store.state.user.authToken; // test 422588688186121165396892813998 
    config.headers['Autorization'] = store.state.user.authToken; // test 422588688186121165396892813998 
    config.headers['usu-id'] = store.state.user.Usu_Id; // test 3061552
    config.headers['emp-id'] = store.state.user.Emp_Id; // test 4

    return config;
}, function (err) {
    return Promise.reject(err);
});

export default axios;