import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import axios from "./middlewares/index";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.prototype.$http = axios;
Vue.use(require('vue-moment'));

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAmuaBRqnZ_CPOHG7OVQsXKN1QuwwmDxE4",
    libraries: "places"
  }
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')