import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import CreateEditContactos from '../views/AdministrarContactos/CreateEditContactos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PaginaInicio',
    component: () => import("../views/PaginaInicio.vue"),
    meta: {
      requiresAuth: true
    }
  },

/* PRODUCTOS */

  {
    path: '/GestionProductos',
    name: 'GestionProductos',
    component: () => import("../views/Productos/GestionProductos.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/producto/:Pys_Id',
    name: 'Producto',
    component: () => import("../views/Productos/Producto.vue"),
    meta: {
      requiresAuth: true
    }
  },


  {
    path: '/GestionCategoria',
    name: 'GestionCategoria',
    component: () => import("../views/Productos/GestionCategoria.vue"),
    meta: {
      requiresAuth: true
    }
  },


  /* BENEFICIOS */
  {
    path: '/GestionBeneficios',
    name: 'GestionBeneficios',
    component: () => import("../views/Beneficios/GestionBeneficios.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/Beneficio/:Ben_Id',
    name: 'Beneficio',
    component: () => import("../views/Beneficios/Beneficio.vue"),
    meta: {
      requiresAuth: true
    }
  },

  /* CUENTA CORRIENTE */
  {
    path: '/GestionPuntos',
    name: 'GestionPuntos',
    component: () => import("../views/CuentaCorriente/GestionPuntos.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/ConsultaPuntos',
    name: 'ConsultaPuntos',
    component: () => import("../views/CuentaCorriente/ConsultaPuntos.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/VencimientoPuntos',
    name: 'VencimientoPuntos',
    component: () => import("../views/CuentaCorriente/VencimientoPuntos.vue"),
    meta: {
      requiresAuth: true
    }
  },

  /* REPORTES */
  {
    path: '/Operaciones',
    name: 'Operaciones',
    component: () => import("../views/Log/Operaciones.vue"),
    meta: {
      requiresAuth: true
    }
  },

  /* STOCK */

  {
    path: '/ConsultaStock',
    name: 'ConsultaStock',
    component: () => import("../views/Stock/ConsultaStock.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/GestionStock',
    name: 'GestionStock',
    component: () => import("../views/Stock/GestionStock.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/stock/:id',
    name: 'EditarStock',
    component: () => import("../views/Stock/Stock.vue"),
    meta: {
      requiresAuth: true
    }
  },


  {
    path: '/BajaStock/:Ben_Id',
    name: 'BajaStock',
    props: true,
    component: () => import("../views/Stock/BajaStock.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/CanjeADM/:Ben_Id',
    name: 'CanjeADM',
    props: true,
    component: () => import("../views/Stock/CanjeADM.vue"),
    meta: {
      requiresAuth: true,
      
    },
  },

  /* CANJES */

  {
    path: '/ConsultaCanje',
    name: 'ConsultaCanje',
    component: () => import("../views/Canjes/ConsultaCanje.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/GestionCanje',
    name: 'GestionCanje',
    props: true,
    component: () => import("../views/Canjes/GestionCanje.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/Pendientes',
    name: 'Pendientes',
    component: () => import("../views/Canjes/Pendientes.vue"),
    meta: {
      requiresAuth: true
    }
  },
 
  /* COMERCIOS */

  {
    path: '/GestionComercio',
    name: 'GestionComercio',
    component: () => import("../views/Comercios/GestionComercio.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/GestionSucursales',
    name: 'GestionSucursales',
    component: () => import("../views/Comercios/GestionSucursales.vue"),
    meta: {
      requiresAuth: true
    }
  },

  /* OTROS */
  {
    path: '/consulta-grupos',
    name: 'ConsultaGrupos',
    component: () => import("../views/CuentaCorriente/ConsultaGrupos.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/configuracion',
    name: 'GestionConfiguracion',
    component: () => import("../views/Configuracion/GestionConfiguracion.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/auth/:token/:usu_id",
    name: "Authenticate",
    props: true,
    component: () => import("../views/access/Authenticate.vue")
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("../views/access/NotFound.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/access/Unauthorized.vue")
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //valida si la route existe
  if (to.matched.length > 0) {//si existe

    //verifica si la route requiere login
    if(to.matched.some(record => record.meta.requiresAuth)) {//si requiere login
      console.log('login requerido');
      if (store.getters.isLoggedIn) {
       /*  console.log('esta logueado y avanza'); */
        next()
        return
      } else {
       /*  console.log('no esta logueado no puede avanzar'); */
        router.push({
          name: 'Unauthorized'
        });
        // Redirige al mapa publico
        // router.push({
        //   name: 'MapaAvisos'
        // })
      }
    } else {//sino requiere login
      console.log('sin login requerido');
      next()
    }
  } else {//sino existe
    router.push({
      name: 'NotFound'
    });
  }
})

export default router
